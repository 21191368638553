import { lazy, Suspense } from "react";

// -------- Pages -------- //
const DashboardOrg = lazy(() => import("../pages/Dashboard"));

// -------- Events Management Pages -------- //
const EventDivision = lazy(() =>
  import("../pages/eventsmanagement/EventDivision")
);
const PendingBookings = lazy(() =>
  import("../pages/eventbooking/PendingBookings")
);
const Transactions = lazy(() => import("../pages/transactions/Transaction"));

//--Organizer Event Booking---//
const AllBookings = lazy(() => import("../pages/eventbooking/AllBookings"));
const AllMovieBookings = lazy(() => import("../pages/moviebookings/AllBookings.jsx"));
const CompletedMovieBookings = lazy(() => import("../pages/moviebookings/CompletedBookings.jsx"));
const PendingMovieBookings = lazy(() => import("../pages/moviebookings/PendingBookings.jsx"));
const RejectMovieBookings = lazy(() => import("../pages/moviebookings/RejectedBookings.jsx"));

const AddOnlineEvent = lazy(() =>
  import("../pages/eventsmanagement/AddOnlineEventOrg")
);
const BookingPage = lazy(() => import("../pages/eventsmanagement/BookingPage"));
const AddVenueEvent = lazy(() =>
  import("../pages/eventsmanagement/AddVenueEventOrg")
);
const updateEvent = lazy(() =>
  import("../pages/eventsmanagement/UpdateEvent")
);
const VenueEventsOrg = lazy(() =>
  import("../pages/eventsmanagement/VenueEventsOrg")
);

const OnlineEventsOrgs = lazy(() =>
  import("../pages/eventsmanagement/OnlineEventsOrgs")
);
const Events = lazy(() => import("../pages/eventsmanagement/Events"));

const CompletedBookings = lazy(() =>
  import("../pages/eventbooking/CompletedBookings")
);


// -------- Orders/Venues/Payout Pages -------- //
const Order = lazy(() => import("../pages/Orders/Order"));
const PayoutRequest = lazy(() => import("../pages/Orders/PayoutRequest"));
const Venues = lazy(() => import("../pages/Orders/Venues"));

// -------- Campaign Pages -------- //
const Reports = lazy(() => import("../pages/campaign/Reports"));
const Reviews = lazy(() => import("../pages/campaign/Reviews"));

// -------- User Management Pages -------- //
const Users = lazy(() => import("../pages/users/Users"));

// -------- Movie Management Pages -------- //
const AddMovie = lazy(() => import("../pages/moviemanagementOrg/AddMovie"));
const AllotTheater = lazy(() =>
  import("../pages/moviemanagementOrg/AllotTheater")
);
const Movies = lazy(() => import("../pages/moviemanagementOrg/Movies"));

const updateMovie = lazy(() => import("../pages/moviemanagementOrg/UpdateMovie"))

const MovieHistory = lazy(() => import("../pages/moviemanagementOrg/BookingPage.jsx"))
const ManageMovie = lazy(() => import("../pages/moviemanagementOrg/ManageMovie.jsx"))

// -------- Support Ticket Pages -------- //
const AddTicketOrg = lazy(() =>
  import("../pages/SupportTicketOrg/AddTicketOrg")
);
const AllTicketsOrg = lazy(() =>
  import("../pages/SupportTicketOrg/AllTicketsOrg")
);
const Tickets = lazy(() =>
  import("../pages/eventsmanagement/Tickets")
)

const AddEventTickets = lazy(() =>
  import("../component/tickets/PricingTabs")
)

const AddMovieTickets= lazy(()=>
import("../component/movieTickets/Tickets.jsx")
)

const MovieTicketUpdate = lazy(()=>
  import("../component/movieTickets/TicketUpdate.jsx")
 )

const EventTicketUpdate = lazy(()=>
 import("../component/tickets/TicketUpdate.jsx")
)
//--PWA Scanner Settings---//
const PwaScanner = lazy(() => import("../pages/pwaScanner/PwaScanner"));
// -------- Edit Profile Pages -------- //
const EditProfileOrgs = lazy(() =>
  import("../pages/EditProfileOrg/EditProfileOrgs")
);
const ChangePasswordOrg = lazy(() =>
  import("../pages/EditProfileOrg/ChangePasswordOrg")
);
const AddTheater = lazy(() => import("../pages/moviemanagementOrg/AddTheater"));

const RejectedBookings = lazy(() =>
  import("../pages/eventbooking/RejectedBookings")
);



const WithdrawRequest = lazy(() => import("../pages/withdraw/WithdrawRequest"));
const MyWithdraws = lazy(() => import("../pages/withdraw/MyWithdraws"));

const renderRoute = (Component) => (
  <Suspense
    fallback={
      <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
        <span className="sr-only">Loading...</span>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
      </div>
    }
  >
    <Component />
  </Suspense>
);

const organizerNavigationRoutes = [
  { path: "", element: renderRoute(DashboardOrg) },
  { path: "events", element: renderRoute(Events) },
  { path: "addevent", element: renderRoute(EventDivision) },
  { path: "updateEvent", element: renderRoute(updateEvent) },
  { path: "addonlineevent", element: renderRoute(AddOnlineEvent) },
  { path: "addvenueevent", element: renderRoute(AddVenueEvent) },
  { path: "allvenueEvents", element: renderRoute(VenueEventsOrg) },
  { path: "allonlineEvents", element: renderRoute(OnlineEventsOrgs) },
  { path: "order", element: renderRoute(Order) },
  { path: "events/bookings", element: renderRoute(BookingPage) },
  { path: "ticket", element: renderRoute(Tickets) },
  { path: "addTicket", element: renderRoute(AddEventTickets) },
  {
    path:"updateTicket",element:renderRoute(EventTicketUpdate)
  },
  { path: "addmovie", element: renderRoute(AddMovie) },
  { path: "updateMovie", element: renderRoute(updateMovie) },
  { path: "allotTheater", element: renderRoute(AllotTheater) },
  { path: "movies", element: renderRoute(Movies) },
  { path: "movies/moviehistory", element: renderRoute(MovieHistory) },
  { path: "movie/manageMovie", element: renderRoute(ManageMovie) },
  { path: "addMovieTicket", element: renderRoute(AddMovieTickets) },
  {
    path:"updateMovieTicket",element:renderRoute(MovieTicketUpdate)
  },
  { path: "payoutrequest", element: renderRoute(PayoutRequest) },
  { path: "reviews", element: renderRoute(Reviews) },
  { path: "reports", element: renderRoute(Reports) },
  { path: "users", element: renderRoute(Users) },
  { path: "venues", element: renderRoute(Venues) },
  { path: "addSupportTicket", element: renderRoute(AddTicketOrg) },
  { path: "allTickets", element: renderRoute(AllTicketsOrg) },
  { path: "editProfile", element: renderRoute(EditProfileOrgs) },
  { path: "changePassword", element: renderRoute(ChangePasswordOrg) },
  { path: "pwascanner", element: renderRoute(PwaScanner) },
  { path: "allbooking", element: renderRoute(AllBookings) },
  { path: "pendingbooking", element: renderRoute(PendingBookings) },
  { path: "completedbooking", element: renderRoute(CompletedBookings) },
  { path: "rejectedbooking", element: renderRoute(RejectedBookings) },
  { path: "allMoviebooking", element: renderRoute(AllMovieBookings) },
  { path: "pendingMoviebooking", element: renderRoute(PendingMovieBookings) },
  { path: "completedMoviebooking", element: renderRoute(CompletedMovieBookings) },
  { path: "rejectedMoviebooking", element: renderRoute(RejectMovieBookings) },
  { path: "transactions", element: renderRoute(Transactions) },
  { path: "mywithdraw", element: renderRoute(MyWithdraws) },
  { path: "mywithdrawform", element: renderRoute(WithdrawRequest) },
  { path: "addtheater", element: renderRoute(AddTheater) },
];

export default organizerNavigationRoutes;
