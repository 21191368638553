import apiAdminPath from "../../constants/admin/auth-org-path.contant";
import axios from "../../configs/axios-instances";

const SubscribersApi = {
  getSubscribersList: ({ payload, success, error }) => {
    const {
      subscriber: { subscriberList },
    } = apiAdminPath;

    axios.postRequest({ path: subscriberList, payload, success, error });
  },

  deleteSubscriber: ({ payload, success, error }) => {
    const {
      subscriber: { deleteSubscriber },
    } = apiAdminPath;

    axios.deleteRequest({ path: deleteSubscriber, payload, success, error });
  },

  mailToSubscribers: ({ payload, success, error }) => {
    const {
      subscriber: { mailToSubscribers },
    } = apiAdminPath;

    axios.postRequest({ path: mailToSubscribers, payload, success, error });
  },
  inviteSubscribers: ({ payload, success, error }) => {
    const {
      subscriber: { inviteSubscribers },
    } = apiAdminPath;

    axios.postRequest({ path: inviteSubscribers, payload, success, error });
  },

  handleContactUs: ({ payload, success, error }) => {
    const {
      subscriber: { contactUs },
    } = apiAdminPath;

    axios.postRequest({ path: contactUs, payload, success, error });
  },

  updateOrganizerListByAdmin: ({ payload, success, error }) => {
    const {
      organizerManagment: { updateOrganiser },
    } = apiAdminPath;
    axios.putRequest({ path: updateOrganiser, payload, success, error });
  },

  deleteOrganizerByAdmin: ({ id, success, error }) => {
    const {
      organizerManagment: { deleteOrganiser },
    } = apiAdminPath;
    const queryParams = [];
    if (id !== undefined && id !== null) queryParams.push(`id=${id}`);
    const pathWithParams =
      queryParams.length > 0
        ? `${deleteOrganiser}?${queryParams.join("&")}`
        : deleteOrganiser;
    axios.deleteRequest({ path: pathWithParams, success, error });
  },
  updateStatus: ({ payload, success, error }) => {
    const {
      organizerManagment: { updateOrganizerStatus },
    } = apiAdminPath;

    const { value, organizer_id } = payload;
    const pathWithParams = `${updateOrganizerStatus}?organizer_id=${organizer_id}&value=${value}`;

    axios.putRequest({ path: pathWithParams, payload, success, error });
  },
};

export default SubscribersApi;
