import axios from "../../configs/axios-instances";
import dashboardPath from "../../constants/dashboard-path.constant";
import eventOrganizerPathConstants from "../../constants/organizer/event-org-path.contant";
const upcomingEventDashboardApi = {
  getAllUpcomingEventsList: ({ params, success, error }) => {
    const {
      upcomingEvents: { getUpcomingEvent },
    } = dashboardPath;
    const { page, limit, location, category, event, dates } = params;
    let pathWithParams = getUpcomingEvent;

    const queryParams = [];
    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (location) queryParams.push(`location=${location}`);
    if (category) queryParams.push(`category=${category}`);
    if (event) queryParams.push(`event=${event}`);
    if (dates) queryParams.push(`dates=${dates}`);

    if (queryParams.length > 0) {
      pathWithParams += `?${queryParams.join("&")}`;
    }

    axios.getRequest({
      path: pathWithParams,
      error,
      success,
    });
  },

  getEventCategories: ({ success, error }) => {
    const {
      upcomingEvents: { getEventCategories },
    } = dashboardPath;
    axios.getRequest({
      path: getEventCategories,
      success,
      error,
    });
  },

  getEventContentById: ({ item_id, success, error }) => {
    const {
      upcomingEvents: { getEventContentById },
    } = dashboardPath;
    axios.getRequest({
      path: `${getEventContentById}?event_id=${item_id}`,
      success,
      error,
    });
  },

  getEventOrganizerById: ({ id, success, error }) => {
    const {
      upcomingEvents: { getEventOrganizerById },
    } = dashboardPath;
    axios.getRequest({
      path: `${getEventOrganizerById}?organizer_id=${id}`,
      success,
      error,
    });
  },

  getEventTicketList: ({ event_id, success, error }) => {
    const {
      EventTickets: { ticketList },
    } = eventOrganizerPathConstants;

    axios.getRequest({
      path: `${ticketList}?event_id=${event_id}`,
      success,
      error,
    });
  },
};

export default upcomingEventDashboardApi;
