import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FaBars,
  FaDashcube,
  FaPlus,
  FaGlobe,
  FaMoneyCheckAlt,
  FaUsers,
  FaUserCircle,
  FaTicketAlt,
  FaBlog,
  FaEnvelopeOpenText,
  FaBell,
  FaCreditCard,
  FaCog,
  FaMobileAlt,
  FaLanguage,
  FaKey,
  FaQuestionCircle,
  FaBullhorn,
  FaSortDown,
  FaSortUp,
} from "react-icons/fa";
import { useStore } from "../../utils/store";





const SidebarAdmin = ({ children }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem("sidebarOpen");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  const {
    basicSettings,
    user: { data },
  } = useStore();

  const [openMenus, setOpenMenus] = useState(() => {
    const savedMenus = localStorage.getItem("openMenus");
    return savedMenus !== null ? JSON.parse(savedMenus) : {};
  });

  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem("sidebarOpen", JSON.stringify(newState));
  };

  const toggleMenu = (menuKey) => {
    setOpenMenus((prevOpenMenus) => {
      const newOpenMenus = {
        ...prevOpenMenus,
        [menuKey]: !prevOpenMenus[menuKey],
      };
      localStorage.setItem("openMenus", JSON.stringify(newOpenMenus));
      return newOpenMenus;
    });

    const anyMenuOpen = Object.values(openMenus).some((isOpen) => isOpen);
    setIsOpen(anyMenuOpen);
  };

  useEffect(() => {
    const paths = location.pathname.split("/").filter((p) => p);
    let newOpenMenus = { ...openMenus };
    let currentPath = "";

    paths.forEach((path) => {
      currentPath += `/${path}`;
      newOpenMenus[currentPath] = true;
    });

    setOpenMenus(newOpenMenus);
    localStorage.setItem("openMenus", JSON.stringify(newOpenMenus));
  }, [location.pathname]);

  const handleNavLinkClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    if (!isOpen) {
      setIsOpen(true);
    }
  };


  const renderProfileSection = (profile) => {
    const isSelectedParent = location.pathname.startsWith(
      "/admin/profile-settings"
    );
    const profileMenuKey = "profile-menu";
    const parentItemStyle = isSelectedParent ? "bg-purple-700 text-white" : "";

    
    return (
      <div>

        <div
          className={`flex items-center p-4 cursor-pointer text-gray-700 hover:bg-purple-700 hover:text-white ${
            openMenus[profileMenuKey] ? "bg-purple-700 text-white" : ""
          } ${parentItemStyle}`}
          onClick={toggleProfileMenu}
        >
          <img
            src={profile.imageSrc}
            alt={profile.name}
            className="w-12 h-12 rounded-full mr-3"
          />
          <div className={`${isOpen ? "block" : "hidden"}`}>{profile.name}</div>
          <div className="ml-auto">
            {isProfileMenuOpen ? <FaSortUp /> : <FaSortDown />}
          </div>
        </div>

        {/* Profile sub-menu */}
        <div className={`pl-4 ${isProfileMenuOpen ? "block" : "hidden"}`}>
          {profile.subMenu.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={`block py-2 px-4 text-gray-700 hover:bg-gray-200 ${
                location.pathname === item.path
                  ? "bg-gray-200 text-gray-700"
                  : ""
              }`}
              onClick={() => {
                toggleProfileMenu();
                handleNavLinkClick();
              }}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    );
  };

  const renderSubMenu = (subMenu, parentKey) => {
    const isSubMenuActive = subMenu.some(
      (item) =>
        location.pathname === item.path ||
        (item.subMenu &&
          renderSubMenu(
            item.subMenu,
            `<span className="math-inline">\{parentKey\}\-</span>${item.name}`
          ))
    );
    
    
    return (
      
      <div className={`pl-4 ${openMenus[parentKey] ? "block" : "hidden"}`}>
        {subMenu.map((item, index) => {
          const menuKey = `<span className="math-inline">\{parentKey\}\-</span>${index}`;

          const isSelected =
            location.pathname === item.path ||
            (item.subMenu && renderSubMenu(item.subMenu, menuKey));

          return (
            <div key={menuKey}>
              {item.subMenu ? (
                <div>
                  <div
                    className={`flex items-center p-4 cursor-pointer text-gray-700 hover:bg-purple-700 hover:text-white 
                      ${openMenus[menuKey] ? "bg-purple-700 text-white" : ""} 
                      ${isSubMenuActive ? "bg-gray-200 text-gray-700" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleMenu(menuKey);
                    }}
                  >
                    <div className="mr-2">{item.icon}</div>
                    <div className={`${isOpen ? "block" : "hidden"}`}>
                      {item.name}
                    </div>
                    <div className="ml-auto">
                      {openMenus[menuKey] ? <FaSortUp /> : <FaSortDown />}
                    </div>
                  </div>
                  {openMenus[menuKey] && (
                    <div>{renderSubMenu(item.subMenu, menuKey)}</div>
                  )}
                </div>
              ) : (
                <NavLink
                  to={item.path}
                  className={`flex items-center p-4 text-gray-700 hover:bg-purple-700 hover:text-white ${
                    isSelected ? "bg-gray-200 text-gray-700" : ""
                  }`}
                  exact
                  onClick={handleNavLinkClick}
                >
                  <div className="mr-2">{item.icon}</div>
                  <div className={`${isOpen ? "block" : "hidden"}`}>
                    {item.name}
                  </div>
                </NavLink>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const menuItem = [
    {
      name: `${data?.name}`,
      imageSrc:
        "https://images.pexels.com/photos/2681751/pexels-photo-2681751.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      subMenu: [
        { path: "/admin/profile-settings/edit-profile", name: "Edit Profile" },
        {
          path: "/admin/profile-settings/change-password",
          name: "Change Password",
        },
        { path: "/", name: "Logout" },
      ],
    },
    { path: "/admin", name: "DashBoard", icon: <FaDashcube /> },
    {
      name: "Event Management",
      icon: <FaPlus />,
      subMenu: [
        {
          path: "/admin/event-management/event-categories",
          name: "Event Categories",
        },
        {
          name: "Event Management",
          subMenu: [
            { path: "/admin/event-management/add-event", name: "Add Event" },
            { path: "/admin/event-management/all-events", name: "All Events" },
            {
              path: "/admin/event-management/venue-events",
              name: "Venue Events",
            },
            {
              path: "/admin/event-management/online-events",
              name: "Online Events",
            },
          ],
        },
      ],
    },
    {
      name: "Movie Management",
      icon: <FaPlus />,
      subMenu: [
        {
          path: "/admin/movie-management/movie-genres",
          name: "Movie Genres",
        },
        {
          name: "Movie Management",
          subMenu: [
            { path: "/admin/movie-management/add-movie", name: "Add Movie" },
            { path: "/admin/movie-management/all-movies", name: "All Movies" },
            {
              path: "/admin/movie-management/theatres",
              name: "Theatres",
            },
          ],
        },
      ],
    },
    {
      name: "Event Bookings",
      icon: <FaGlobe />,
      subMenu: [
        { path: "/admin/event-booking/all-bookings", name: "All Bookings" },
        {
          path: "/admin/event-booking/completed-booking",
          name: "Completed Bookings",
        },
        {
          path: "/admin/event-booking/pending-booking",
          name: "Pending Bookings",
        },
        {
          path: "/admin/event-booking/rejected-booking",
          name: "Rejected Bookings",
        },
        // { path: "/admin/event-booking/report", name: "Report" },
        {
          name: "Settings",
          subMenu: [
            // {
            //   path: "/admin/event-booking/settings/guest-checkout",
            //   name: "Guest Checkout",
            // },
            { path: "/admin/event-booking/settings/coupon", name: "Coupons" },
            {
              path: "/admin/event-booking/settings/tax-comission",
              name: "Tax & Commission",
            },
          ],
        },
      ],
    },
    {
      name: "Movie Bookings",
      icon: <FaGlobe />,
      subMenu: [
        { path: "/admin/movie-booking/all-bookings", name: "All Bookings" },
        {
          path: "/admin/movie-booking/completed-bookings",
          name: "Completed Bookings",
        },
        {
          path: "/admin/movie-booking/pending-bookings",
          name: "Pending Bookings",
        },
        {
          path: "/admin/movie-booking/rejected-bookings",
          name: "Rejected Bookings",
        },
        // { path: "/admin/movie-booking/report", name: "Report" },
        {
          name: "Settings",
          subMenu: [
            // {
            //   path: "/admin/event-booking/settings/guest-checkout",
            //   name: "Guest Checkout",
            // },
            { path: "/admin/event-booking/settings/coupon", name: "Coupons" },
            {
              path: "/admin/event-booking/settings/tax-comission",
              name: "Tax & Commission",
            },
          ],
        },
      ],
    },
    {
      name: "Withdraw Method",
      icon: <FaMoneyCheckAlt />,
      subMenu: [
        { path: "/admin/withdraw-method/request", name: "Withdraw Requests" },
      ],
    },
    {
      path: "/admin/transcations",
      name: "Transactions",
      icon: <FaMoneyCheckAlt />,
    },
    {
      name: "Organizer Management",
      icon: <FaUsers />,
      subMenu: [
        {
          path: "/admin/organizer-management/registered-organizer",
          name: "Registered Organizers",
        },
        {
          path: "/admin/organizer-management/add-organizer",
          name: "Add Organizer",
        },
        { path: "/admin/organizer-management/settings", name: "Settings" },
      ],
    },
    {
      name: "Customer Management",
      icon: <FaUserCircle />,
      subMenu: [
        {
          path: "/admin/customer-management/registered-customer",
          name: "Registered Customers",
        },
        {
          path: "/admin/customer-management/add-customer",
          name: "Add Customer",
        },
      ],
    },
    {
      name: "Admin Management",
      icon: <FaUserCircle />,
      subMenu: [
        {
          path: "/admin/admin-management/registered-admin",
          name: "Registered Admins",
        },
        {
          path: "/admin/admin-management/add-admin",
          name: "Add Admin",
        },
        {
          path:"/admin/role-permissions",
          name:"Role Management"
        }
      ],
    },
    
    {
      name: "Support Ticket",
      icon: <FaTicketAlt />,
      subMenu: [
        { path: "/admin/support-tickets/settings", name: "Settings" },
        { path: "/admin/support-tickets/all-tickets", name: "All Tickets" },
        {
          path: "/admin/support-tickets/pending-tickets",
          name: "Pending Tickets",
        },
        { path: "/admin/support-tickets/open-tickets", name: "Open Tickets" },
        {
          path: "/admin/support-tickets/close-tickets",
          name: "Closed Tickets",
        },
      ],
    },
    {
      name: "Blog Management",
      icon: <FaBlog />,
      subMenu: [
        { path: "/admin/blog-management/categories", name: "Categories" },
        { path: "/admin/blog-management/all-blogs", name: "Blog" },
      ],
    },
    {
      path: "/admin/faqs",
      name: "FAQ Management",
      icon: <FaQuestionCircle />,
    },
    {
      path: "/admin/announce-popups",
      name: "Announcement Popups",
      icon: <FaBullhorn />,
    },
    {
      path: "/admin/headers",
      name: "Announcement Headers",
      icon: <FaBullhorn />,
    },
    {
      name: "Subscribers",
      icon: <FaEnvelopeOpenText />,
      subMenu: [
        { path: "/admin/subscribers", name: "Subscribers" },
        { path: "/admin/mail-to-subscribers", name: "Mail to Subscribers" },
        { path: "/admin/invite-to-subscribers", name: "Invite Subscribers" },
      ],
    },
    // {
    //   name: "Push Notification",
    //   icon: <FaBell />,
    //   subMenu: [
    //     { path: "/admin/push-notification/settings", name: "Settings" },
    //     {
    //       path: "/admin/push-notification/notification",
    //       name: "Send Notification",
    //     },
    //   ],
    // },
    {
      name: "Payment Gateways",
      icon: <FaCreditCard />,
      subMenu: [
        {
          path: "/admin/payment-gateway/online-gateway",
          name: "Online Gateways",
        },
        {
          path: "/admin/payment-gateway/offline-gateway",
          name: "Offline Gateways",
        },
      ],
    },
    {
      name: "Basic Settings",
      icon: <FaCog />,
      subMenu: [
        {
          path: "/admin/basic-settings/general-settings",
          name: "General Settings",
        },
        {
          name: "Email Settings",
          subMenu: [
            {
              path: "/admin/basic-settings/email-settings/mail-from-admin",
              name: "Mail from Admin",
            },
            {
              path: "/admin/basic-settings/email-settings/mail-to-admin",
              name: "Mail to Admin",
            },
            {
              path: "/admin/basic-settings/email-settings/mail-templates",
              name: "Mail Templates",
            },
          ],
        },
        { path: "/admin/basic-settings/breadcrumb", name: "Breadcrumb" },
        { path: "/admin/basic-settings/page-headings", name: "Page Headings" },
        { path: "/admin/basic-settings/plugins", name: "Plugins" },
        // {
        //   path: "/admin/basic-settings/seo-information",
        //   name: "SEO Informations",
        // },
        // {
        //   path: "/admin/basic-settings/maintenance-mode",
        //   name: "Maintenance Mode",
        // },
        // { path: "/admin/basic-settings/cookie-alert", name: "Cookie Alert" },
        { path: "/admin/basic-settings/footer-logo", name: "Footer Logo" },
        // { path: "/admin/basic-settings/social-media", name: "Social Medias" },
      ],
    },
    {
      name: "PWA Scanner",
      icon: <FaMobileAlt />,
      subMenu: [
        {
          path: "/admin/pwa-settings/pwa-scanner-settings",
          name: "PWA Scanner ",
        },
      ],
    },
    {
      path: "/admin/language-management",
      name: "Language Management",
      icon: <FaLanguage />,
    },
    // {
    //   path: "/admin/edit-language-keyword",
    //   name: "Admin Language Keywords",
    //   icon: <FaKey />,
    // },
  ];

  const permissions= data?.permissions;

  function filterMenuItem(menuItem, permissions) {
    return menuItem.filter(item => {
      // Check if the item has a name property
      if (!item.name) return false;
  
      // Check if the name of the item is present in the permissions array
      return permissions?.includes(item.name);
    });
  }



 const filteredMenu = filterMenuItem(menuItem, permissions);

  const filteredMenuItems = filteredMenu.filter((item) => {
    if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
      return true;
    }
    if (item.subMenu) {
      return item.subMenu.some((subItem) =>
        subItem.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return false;
  });

  return (
    <div className="flex">
      <div
        className={`fixed z-50 h-full overflow-y-auto bg-white transition-all duration-300 ${
          isOpen ? "w-64" : "w-20"
        }`}
      >
        {/* Sidebar content */}
        <div className="flex items-center p-4">
          <h1 className={`text-xl font-bold ${isOpen ? "block" : "hidden"}`}>
            {basicSettings?.website_title || "TicketVerse"}
          </h1>
          <div className="ml-auto cursor-pointer">
            <FaBars onClick={toggleSidebar} />
          </div>
        </div>

        {/* Search input */}
        <div className="pt-2">
          {/* Render menu items */}
          {filteredMenuItems.map((item, index) => {
            const itemKey = `menu-${index}`;
            const isSelectedParent = item.subMenu?.some((subItem) =>
              location.pathname.startsWith(subItem.path)
            );
            const parentItemStyle = isSelectedParent
              ? "bg-purple-700 text-white"
              : "";

            return (
              <div key={itemKey}>
                {item.imageSrc && index === 0 && renderProfileSection(item)}
                {!item.imageSrc && item.subMenu && (
                  <div>
                    <div
                      className={`flex items-center p-3 cursor-pointer text-gray-700  text-sm hover:bg-purple-700 hover:text-white ${
                        openMenus[itemKey] ? "bg-purple-700 text-white" : ""
                      } ${parentItemStyle}`}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleMenu(itemKey);
                      }}
                    >
                      <div className="mr-2">{item.icon}</div>
                      <div className={`${isOpen ? "block" : "hidden"}`}>
                        {item.name}
                      </div>
                      <div className="ml-auto">
                        {openMenus[itemKey] ? <FaSortUp /> : <FaSortDown />}
                      </div>
                    </div>
                    {openMenus[itemKey] && (
                      <div className="text-sm p-1">{renderSubMenu(item.subMenu, itemKey)}</div>
                    )}
                  </div>
                )}
                {!item.imageSrc && !item.subMenu && (
                  <NavLink
                    to={item.path}
                    className={`flex items-center p-2 text-sm text-gray-700 hover:bg-purple-700 hover:text-white ${
                      location.pathname === item.path
                        ? "bg-purple-700 text-white"
                        : ""
                    }`}
                    exact
                    onClick={handleNavLinkClick}
                  >
                    <div className="mr-2">{item.icon}</div>
                    <div className={`${isOpen ? "block" : "hidden"}`}>
                      {item.name}
                    </div>
                  </NavLink>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <main
        className={`transition-all duration-300 ${
          isOpen ? "ml-64" : "ml-20"
        } p-4`}
      >
        {children}
      </main>
    </div>
  );
};

export default SidebarAdmin;
