import React, { useEffect, lazy, Suspense, startTransition } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "../src/utils/store";
import navigationRoutes from "./router/customer.router";
import organizerNavigationRoutes from "./router/organizer.router";
import adminNavigationRoutes from "./router/admin.router";
import basicSetting from "./apis/adminApis/basicSetting.api";
const LayoutCustomer = lazy(() => import("./component/sharedCustomer/Layout"));
const Layout = lazy(() => import("./component/shared/Layout"));
const LayoutAdmin = lazy(() => import("./component/shared/LayoutAdmin"));

const Home = lazy(() => import("./pages/home/Home"));
const Footer = lazy(() => import("./component/footer/Footer"));

function App() {
  const {
    user: { isAuthenticated, role },
    verifySession,
    basicSettings,
    setBasicSettings,
    setUserLocation,
  } = useStore();

  useEffect(() => {
    basicSetting.getGeneralBasicSetting({
      success: (res) => {
        const settings = res.data?.basicSettings;
        setBasicSettings(settings);
        if (settings) {
          const faviconLink = document.querySelector("link[rel='icon']");
          if (faviconLink) {
            faviconLink.href = settings.favicon;
          }
          document.documentElement.style.setProperty(
            "--color-primary",
            settings.primary_color
          );
          document.title = settings.website_title || "Default Title";
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }, []);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    setUserLocation(crd.latitude, crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "denied") {
  //         }
  //       });
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  const renderRoute = (Component) => (
    <Suspense
      fallback={
        <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
          <span className="sr-only">Loading...</span>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
        </div>
      }
    >
      <Component />
    </Suspense>
  );

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={renderRoute(Home)} />
        <Route>
          {navigationRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child.path}
                    element={child.element}
                  />
                ))}
            </Route>
          ))}
        </Route>

        <Route path="/organizer/*" element={<Layout />}>
          {organizerNavigationRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child.path}
                    element={child.element}
                  />
                ))}
            </Route>
          ))}
        </Route>

        <Route path="/admin/*" element={<LayoutAdmin />}>
          {adminNavigationRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child.path}
                    element={child.element}
                  />
                ))}
            </Route>
          ))}
        </Route>
      </Routes>
      {/* {showNavbarFooter && renderRoute(Footer)} */}
      <ToastContainer autoClose={5000} />
    </div>
  );
}

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
