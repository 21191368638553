const BASEURL = process.env.REACT_APP_BACKEND;

const setBaseUrlPrefix = (path) => {
  return BASEURL.concat(path);
};

const eventOrganizerPathConstants = {
  eventManage: {
    addEventByOrganizer: setBaseUrlPrefix("/org/addEvent"),
    listEventByOrganizer: setBaseUrlPrefix("/org/listEvent"),
    getEventCategoriesList: setBaseUrlPrefix("/org/listCategories"), //get total event categories
    getEventByIdOrganizer: setBaseUrlPrefix("/org/getEvent"),
    updateEventByOrganizer: setBaseUrlPrefix("/org/updateEvent"),
    getEventImagesById: setBaseUrlPrefix("/org/getEventImages"),
    deleteEventById: setBaseUrlPrefix("/org/deleteEvent"),
  },

  categoryManage: {
    addCategoryByAdmin: setBaseUrlPrefix("/org/addCategory"),
    updateCategoryByAdmin: setBaseUrlPrefix("/org/updateCategory"),
    deleteCategoryByAdmin: setBaseUrlPrefix("/org/deleteCategory"),
    listSeatsCategories:setBaseUrlPrefix("/org/getSeatsCategories"),
    addGenre: setBaseUrlPrefix("/org/addGenre"),
    updateGenre: setBaseUrlPrefix("/org/updateGenre"),
    deleteGenre: setBaseUrlPrefix("/org/deleteGenre"),
    listGenre:setBaseUrlPrefix("/org/listGenres")
  },

  movieManage: {
    addMovieByOrganizer: setBaseUrlPrefix("/org/addMovie"),
    listMovieByOrganizer: setBaseUrlPrefix("/org/listMovies"),
    getListMovies: setBaseUrlPrefix("/org/listGenres"),
    getMovieByIdOrganizer: setBaseUrlPrefix("/org/getMovie"),
    updateMovieByOrganizer: setBaseUrlPrefix("/org/updateMovie"),
    getMovieImagesById: setBaseUrlPrefix("/org/getMovieImages"),
    deleteMovieById: setBaseUrlPrefix("/org/deleteMovie"),
  },
  EventTickets: {
    addEventTicket: setBaseUrlPrefix("/org/addTicket"),
    updateEventTicket: setBaseUrlPrefix("/org/updateTicket"),
    ticketList: setBaseUrlPrefix("/org/getTicketList"),
    deleteTicket: setBaseUrlPrefix("/org/deleteTicket"),
  },
};

export default eventOrganizerPathConstants;
