const BASEURL = process.env.REACT_APP_BACKEND;


const setBaseUrlPrefix = (path) => {
  return BASEURL.concat(path);
};

const apiAdminPathConstants = {

    authAdmin: {
        
        loginAdmin: setBaseUrlPrefix("/admin/login"),
        logoutAdmin: setBaseUrlPrefix("/admin/logout"),
        registerAdmin: setBaseUrlPrefix("/admin/register"),
        verifySessionAdmin: setBaseUrlPrefix("/admin/verify-session"),
        forgotPasswordAdmin: setBaseUrlPrefix("/admin/forgot-password"),
        resetPasswordTokenAdmin: setBaseUrlPrefix("/admin/reset-password/:password_reset_token"),
        verifyEmailAdmin: setBaseUrlPrefix("/admin/verify-email/:verification_token"),
        updateProfileAdmin: setBaseUrlPrefix("/admin/adminProfile"),
        deleteAdmin:setBaseUrlPrefix("/admin/deleteAdmin"),
        // add role for admins
        listAdmin:setBaseUrlPrefix("/admin/adminList"),
        updateAdmin:setBaseUrlPrefix("/admin/updateAdmin"),
        updateAdminStatus:setBaseUrlPrefix("/admin/updateAdminStatus"),
        addRoleAdmin: setBaseUrlPrefix("/admin/addRole"),
        updateRoleAdmin: setBaseUrlPrefix("/admin/updateRole"),
        listOfRoleAdmin: setBaseUrlPrefix("/admin/listRoles"),
        deleteRoleAdmin: setBaseUrlPrefix("/admin/deleteRole"),
        approveUser: setBaseUrlPrefix("/admin/approveUser"),
    },

    dashBoardAdminStats: {
      getAdminDashboardStats: setBaseUrlPrefix("/admin/adminStats"),
      getEarningStats:setBaseUrlPrefix("/admin/earingStats"),
    },

    getListEventsAdminStats: {
      getAdminEventStats: setBaseUrlPrefix("/admin/eventList"),
    },

    bookingAdmin: {
      getAllBookingAdmin: setBaseUrlPrefix("/bookings/allBookingAdmin"),
      getReportAdmin: setBaseUrlPrefix("/bookings/bookingReportAdmin"),
    },

    supportTicketAdmin: {
      getTicketListAdmin: setBaseUrlPrefix("/support/ticketListAdmin"),
      updateStatus:setBaseUrlPrefix("/support/updateStatus"),
    },
    subscriber:{
      subscriberList:setBaseUrlPrefix("/feature/subscribers"),
      addSubscriber:setBaseUrlPrefix("/feature/addSubscriber"),
      deleteSubscriber:setBaseUrlPrefix("/feature/deleteSubscriber"),
      mailToSubscribers:setBaseUrlPrefix("/feature/mailSubscribers"),
      inviteSubscribers:setBaseUrlPrefix("/feature/inviteSubscribers"),
      contactUs:setBaseUrlPrefix("/feature/mailToAdmin")
    },
    organizerManagment: {
      getOrganiserList: setBaseUrlPrefix("/admin/orglist"),
      organiserList: setBaseUrlPrefix("/admin/getOrgList"),
      updateOrganiser: setBaseUrlPrefix("/admin/handleUpdateOrganizer"),
      deleteOrganiser: setBaseUrlPrefix("/admin/deleteOrganizer"),
      updateOrganizerStatus:setBaseUrlPrefix("/admin/updateOrgStatus"),
      addOrganizer: setBaseUrlPrefix("/auth/org/register")
    },

    customerManagment: {
      getCustomerList: setBaseUrlPrefix("/admin/customerlist"),
      updateCustomer: setBaseUrlPrefix("/admin/handleUpdateCustomer"),
      deleteCustomer: setBaseUrlPrefix("/admin/deleteCustomer"),
      updateCustomerStatus:setBaseUrlPrefix("/admin/updateStatus"),
      addCustomer:setBaseUrlPrefix("/auth/register")
    },
    basicSetting:{
      getBasicSetting:setBaseUrlPrefix("/admin/basicSettingAdmin"),
      addBasicSetting:setBaseUrlPrefix("/admin/basicSetting"),
      getGeneralBasicSetting:setBaseUrlPrefix("/admin/basicSetting")
    },
    payment:{
      onlineGateway:setBaseUrlPrefix("/book/listOnlineGateways"),
      updateGateway:setBaseUrlPrefix("/book/updateOnlineGateways")
    }
  };
  
  export default apiAdminPathConstants;