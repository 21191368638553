import apiAdminPath from "../../constants/admin/auth-org-path.contant";
import axios from "../../configs/axios-instances";

const adminManagementAdminApi = {
  getAdminList: ({ payload, success, error }) => {
    const {
      authAdmin: { listAdmin },
    } = apiAdminPath;
    axios.postRequest({ path: listAdmin, payload, success, error });
  },
  addAdmin: ({ payload, success, error }) => {
    const {
      organizerManagment: { addOrganizer },
    } = apiAdminPath;
    axios.postRequest({ path: addOrganizer, payload, success, error });
  },

  updateAdmin: ({ payload, success, error }) => {
    const {
      authAdmin: { updateAdmin },
    } = apiAdminPath;

    axios.putRequest({ path: updateAdmin, payload, success, error });
  },

  deleteAdmin: ({ payload, success, error }) => {
    const {
      authAdmin: { deleteAdmin },
    } = apiAdminPath;

    axios.deleteRequest({ path: deleteAdmin, payload, success, error });
  },
  updateStatus: ({ payload, success, error }) => {
    const {
      authAdmin: { updateAdminStatus },
    } = apiAdminPath;

    const { value, id } = payload;
    const pathWithParams = `${updateAdminStatus}?id=${id}&value=${value}`;

    axios.putRequest({ path: pathWithParams, payload, success, error });
  },
};

export default adminManagementAdminApi;
